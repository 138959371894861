// Importing required dependencies
import { Injectable } from '@angular/core';
import { AlertController, AlertOptions, ActionSheetController, ActionSheetOptions, LoadingController, LoadingOptions, Platform, PopoverController, PopoverOptions, ToastController, ToastOptions, MenuController } from '@ionic/angular';
import { OrderByDirection } from 'firebase/firestore';
import { Browser, OpenOptions } from '@capacitor/browser';

// The service is provided in root scope, making it available everywhere in the app
@Injectable({
  providedIn: 'root'
})

/**
 * The IonicService provides functions to handle Ionic specific functionalities 
 * such as alert and loading indicators across the ReadyView application.
 * @class IonicService
 * @author Brady Synstelien
 */
export class IonicService {

  /**
   * Constructs an instance of the IonicService.
   * @param {AlertController} alertCtrl An instance of Ionic's AlertController
   * @param {LoadingController} loadingCtrl An instance of Ionic's LoadingController
   */
  constructor(public alertCtrl: AlertController,
              public actionSheetCtrl: ActionSheetController,
              public loadingCtrl: LoadingController,
              public toastCtrl: ToastController,
              public popOverCtrl: PopoverController,
              public menuCtrl: MenuController,
              // public browser: Browser, #TODO
              public platform: Platform) { }

  /**
   * Presents an alert dialog with the provided options.
   * @param {AlertOptions} opts Configuration options for the alert
   * @return {Promise<HTMLIonAlertElement>} The presented alert instance
   */
  async alert(opts: AlertOptions) {
    const alert = await this.alertCtrl.create(opts)
    await alert.present()
    return alert
  }

  /**
   * Presents an action sheet with the provided options.
   * @param {AlertOptions} opts Configuration options for the alert
   * @return {Promise<HTMLIonAlertElement>} The presented alert instance
   */
  async actionSheet(opts: ActionSheetOptions) {
    const actionSheet = await this.actionSheetCtrl.create(opts)
    await actionSheet.present()
    return actionSheet
  }

  /**
   * Presents a loading indicator with the provided options.
   * @param {LoadingOptions} opts Configuration options for the loading indicator
   * @return {Promise<HTMLIonLoadingElement>} The presented loading indicator instance
   */
  async load(opts: LoadingOptions) {
    const load = await this.loadingCtrl.create(opts)
    await load.present()
    return load
  }

  async toast(opts: ToastOptions) {
    const defaultOptions: ToastOptions = 
      {
        duration: 2000,
        cssClass: "centering-class"
        //... add any other default values you'd like here
      };

    const finalOptions = 
      {
        ...defaultOptions, 
        ...opts
      }; // Override default options with provided options

    const toast = await this.toastCtrl.create(finalOptions)
    await toast.present()
    return toast
  }

  async popover(opts: PopoverOptions) {
    const defaultOptions: PopoverOptions = 
      {
        cssClass: "default-pop-over-style",
        component: ""
        //... add any other default values you'd like here
      }

    const finalOptions = 
      {
        ...defaultOptions,
        ...opts
      }

    const popover = await this.popOverCtrl.create(finalOptions) 
    await popover.present()
    return popover
  }

  public isPlatform(platformName: "ipad" | "iphone" | "ios" | "android" | "phablet" | "tablet" | "cordova" | "capacitor" | "electron" | "pwa" | "mobile" | "mobileweb" | "desktop" | "hybrid"): boolean {
    return this.platform.is(platformName)
  }

  public openBrowser(opts: OpenOptions) {
    return Browser.open(opts)
  }
}