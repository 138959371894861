/**
 * Class to handle the authentication operations such as login and logout.
 * 
 * @class AuthenticationService
 * 
 * @author Brady Synstelien
 */

// Import required dependencies
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Platform, MenuController } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",  // Angular service provided in root scope
})

export class AuthenticationService {
  // BehaviorSubject representing the authentication state
  authenticationState = new BehaviorSubject(false);

  /**
   * AuthenticationService constructor. Initializes the authentication service.
   * @param {Storage} storage - Ionic Storage instance for persistent data storage
   * @param {AngularFireAuth} firebaseAuth - AngularFireAuth instance for firebase authentication
   * @param {MenuController} menuCtrl - Ionic MenuController instance for controlling menu interactions
   */
  constructor(
    private storage: Storage,
    private firebaseAuth: AngularFireAuth,
  ) {
    // Subscribes to the firebase auth state and triggers logout if user isn't authenticated
    this.firebaseAuth.authState.subscribe(res => {
      if (!res) {
        this.logout()
      }
    })
  }

  /**
   * Logout function. Signs out the user from Firebase, clears the storage, and disables the menu.
   */
  async logout() {
    await this.storage.remove("uid");
    await this.storage.remove("user_profile")
    await this.firebaseAuth.signOut();
    //window.location.reload(true);
  }

  /**
   * Sets the authentication state.
   * @param {boolean} value - New authentication state
   */
  setAuthenticationState(value: boolean) {
    this.authenticationState.next(value);
  }

  /**
   * Returns the authentication state.
   * @return {boolean} Current authentication state
   */
  isAuthenticated(): boolean {
    return this.authenticationState.value;
  }
}