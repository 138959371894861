// Importing required dependencies
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Storage } from "@ionic/storage";
import { User } from "../interfaces/user";
import { uri } from "src/environments/environment";
import { IonicService } from "./ionic.service";
import { catchError, finalize, Subject, BehaviorSubject, take, map, firstValueFrom, Observable, filter } from "rxjs";
import { AlertButton, AlertInput } from "@ionic/angular";

// The service is provided in root scope, making it available everywhere in the app
@Injectable({
  providedIn: "root",
})

/**
 * The UserProfileService handles user-specific functions for the ReadyView application.
 * It manages user data and associates the user with an ISP if they log in with an @company.com email.
 * 
 * https://blog.angular-university.io/how-to-build-angular2-apps-using-rxjs-observable-data-services-pitfalls-to-avoid/
 * 
 * @class UserProfileService
 * @author Brady Synstelien
 */
export class UserProfileService {

  private token!: string;
  private validNocId = false;

  private _userObject: Subject<User | undefined> = new BehaviorSubject<User | undefined>(
    undefined
  );

  public readonly userObject: Observable<User | undefined> = this._userObject.asObservable()

  /**
   * Constructs an instance of the UserProfileService.
   * @param {HttpClient} http - Angular's HTTP client
   * @param {AngularFireAuth} firebaseAuth - AngularFire authentication object
   * @param {Storage} storage - Ionic storage object
   */
  constructor(
    private http: HttpClient,
    private firebaseAuth: AngularFireAuth,
    private ionic: IonicService,
    private storage: Storage,
  ) {}

 /**
 * Sets the user object with given data and performs cloud authorization and data storage simultaneously.
 * @param userData User data object to be set
 * @returns Promise resulting from allSettled combination of cloud authorization and user data storage
 * @author Brady Synstelien
 */
async setUserObject(userData?: User) {
  // Assign the passed user data to the user object
  this._userObject.next(userData)

  // Execute rliCloudAuthorization and storage.set operations together
  // This is achieved using Promise.allSettled() that runs multiple promises in parallel
  return Promise.allSettled([this.rliCloudAuthorization(), this.storage.set("user_profile", userData)]);
}

/**
 * Private method for cloud authorization. Fetches a token and verifies the user's organization.
 * @returns A promise resolving to a success message upon authorization, or throws an error otherwise
 * @author Brady Synstelien
 */
private async rliCloudAuthorization(): Promise<string | void> {

  const org_id = await this.organizationId()
  const api_key = await this.getApiKey()
  const user_id = await this.getUserID()

  // Defining the headers for the authorization request
  const headers = new HttpHeaders({
    "Content-Type": "application/json",
    "Authorization": 'Basic ' + String(window.btoa(org_id + ":" + api_key)),
    "X-Api-Key": api_key,
    "Organization-Id": org_id,
  });

  // Get current user from firebaseAuth
  const user = await firstValueFrom(this.firebaseAuth.authState)

  // If there's no current user, throw an error
  if (!user) throw "Current user not defined";

  // Get the token from the current user
  const token = await user.getIdTokenResult(true);

  // Assign user and token to a variable
  const response = { "token": token, "user": user };

  // If the user's organization is not the same as the current one
  if (!response.token.claims['org_id'] || response.token.claims['org_id'] != org_id) {
    // Make post request for authorization
    try {
      const res = await firstValueFrom(this.http
        .post(
          uri.fast + "v1/company/rli-cloud-storage/auth",
          { 'uid': user_id },
          { headers: headers }
        )
      )

      // On successful post request, get a new ID token and return a success message
      await response.user.getIdToken(true);
      return "Authenticated for organization " + org_id;
    } catch (error) {
      console.log("error")
      // If there's an error, throw it
      throw error;
    }
  } else {
    // If the user is already authenticated for the organization, return a message
    return "Already authenticated for organization " + org_id;
  }
}

/**
 * Method to fetch the API key for search.
 * @returns A promise resolving to the API key string upon successful request, or throws an error otherwise
 * @author Brady Synstelien
 */
public getSearchApiKey(): Promise<string> {
  return new Promise<string>(async (resolve, reject) => {
    const org_id = await this.organizationId()
    const api_key = await this.getApiKey()
    const user_id = await this.getUserID()

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": 'Basic ' + String(window.btoa(org_id + ":" + api_key)),
      "X-Api-Key": api_key,
      "Organization-Id": org_id,
    });

    try {
      const res = await firstValueFrom(
        this.http
          .get(uri.fast+"v1/search/auth/token/" + user_id, 
          { 
            headers: headers 
          }
        )
      )
      resolve(res as string)
    } catch(error) {
      reject(error)
    }
  });
}
  


sendPasswordResetEmailAlert() {
  const inputs: AlertInput[] = 
      [
        {
          placeholder: "Enter Email",
          name: "text"
        }
      ]
  const buttons: AlertButton[] =
    [
      {
        text: "Cancel",
        role: 'cancel'
      },
      {
        text: "Send",
        handler: async (data) => {
          const loader = await this.ionic.load(
            {
              message: "Sending password reset email..."
            }
          )

          this.sendPasswordResetEmail(data.text)
            .pipe(
              finalize(() => loader.dismiss())
            )
            .subscribe(
              {
                next: () => {
                  this.ionic.toast(
                    {
                      message: "Success! Check your inbox for the reset link",
                      color: "success",
                      cssClass: "my-custom-class",
                      duration: 3000,
                    }
                  )
                }
              }
            )
        }
      }
    ]
  this.ionic.alert(
    {
      header: "Forgot Password?",
      subHeader: "Enter your email address and we will send you an email with instructions for resetting your password.",
      buttons: buttons,
      inputs: inputs
    }
  )
}

sendPasswordResetEmail(email: string) {
  return this.http
    .get(
      uri.fast+"v1/company/readyview/password/reset/"+email, 
      {headers: new HttpHeaders({"Content-Type": "application/json"})}
    )
    .pipe(
      catchError((error) => {
        this.ionic.alert(
          {
            'header': "Password Reset Email Error",
            'message': error.error?.detail ? error.error?.detail : "Failed to send reset password email",
            'buttons': ["Dismiss"]
          }
        )
        throw error
      })
    )
}


getUserObservable() {
  return this.userObject.pipe(
    filter(res => res != undefined) // allow only non-undefined values
  ) as Observable<User>
}

/**
 * Asynchronously retrieves the latest User object emitted from the userObject observable.
 * 
 * @returns {Promise<User>} 
 *  A promise that resolves to the latest User object emitted by the observable.
 * 
 * @example
 * 
 * (async () => {
 *  const user = await getUserObject();
 *  console.log(user);
 * })();
 * 
 * @author YourName
 */
async getUserObject(): Promise<User> {
  return await firstValueFrom(
    this.getUserObservable().pipe(
      filter(res => res != undefined) // allow only non-undefined values
    ) as Observable<User>
  );
}

/**
 * Constructs and returns an Promise emitting the path for the user in the format: "ISPs/{organization_id}/Users/{user_id}".
 * @returns Promise<string> emitting the user path
 * @author Brady Synstelien
 */
getUserPath(): Promise<string> {
  return firstValueFrom( 
    this.getUserObservable().pipe(
      map(user => {
        if (user && user["organization_id"] && user["id"]) {
          return `ISPs/${user["organization_id"]}/Users/${user["id"]}`
        }
        throw new Error("User object or email field is not defined.");
      })
    )
  )
}

/**
 * Sets the token after appending "Bearer " to it.
 * @param token The token to be set
 * @author Brady Synstelien
 */
setToken(token: string): void {
  this.token = "Bearer " + token;
}

/**
 * Returns the token.
 * @returns String representing the token
 * @author Brady Synstelien
 */
getToken(): string {
  return this.token;
}

/**
 * Returns a Promise emitting the email of the user from the user object.
 * @returns Promise<string> emitting the user's email
 * @author Brady Synstelien
 */
getUser(): Promise<string> {
  return firstValueFrom(
    this.getUserObservable().pipe(
      map(user => {
        if (user && user["email"]) {
          return user["email"];
        }
        throw new Error("User object or email field is not defined.");
      })
    )
  );
}

/**
 * Returns an Promise emitting the ID of the user from the user object.
 * @returns Promise<string> emitting the user's ID
 * @author Brady Synstelien
 */
getUserID(): Promise<string> {
  return firstValueFrom(
    this.getUserObservable().pipe(
      map(user => {
        if (user && user["id"]) {
          return user["id"];
        }
        throw new Error("User object or ID field is not defined.");
      })
    )
  ) 
}

/**
 * Returns an Promise emitting the permission level of the user from the user object.
 * @returns Promise<number> emitting the user's permission level
 * @author Brady Synstelien
 */
getPermissionLevel(): Promise<number> {
  return firstValueFrom(
    this.getUserObservable().pipe(
      map(user => {
        if (user && typeof user["permission_level"] === "number") {
          return user["permission_level"];
        }
        throw new Error("User object or permission_level field is not defined or invalid.");
      })
    )
  )
}

/**
 * Checks if the current user is an admin or not.
 * @returns Promise resolving to a boolean indicating if the user is an admin
 * @author Brady Synstelien
 */
async isAdmin(): Promise<boolean> {
  const user = await firstValueFrom(this.firebaseAuth.authState)

  if (user) {
    try {
      const token = await user.getIdTokenResult(true)
      return token.claims['admin']
    } catch(error) {
      throw error
    }
  }
  else {
    throw "Current user not defined"
  }
}

/**
 * Returns a Promise of the user's team.
 * Throws an error if the team field is not valid or not defined.
 */
getTeam(): Promise<string> {
  return firstValueFrom(
    this.getUserObservable().pipe(
      map(user => {
        // Check if user object and team field exist
        if (user && typeof user.team === 'string') {
          return user.team;
        }
        throw new Error('User object or team field is not defined or invalid.');
      })
    )
  )
}

/**
 * Sets the account_id field in the user object.
 * @param id The id to be set in the account_id field
 */
async setReadyOn(id: string) {
  // Update the user object and emit it as the next value
  this.getUserObservable()
    .pipe(
      take(1),
      map( 
        (res) => {
          if(id != "None" && typeof id == "string") { 
            try {
              this._userObject.next(
                {
                  ...res, 
                  account_id: id
                }
              )
            }
            catch (error) {
              throw error
            }
          }
        }
      )
    )
}

/**
 * Returns an Promise of the account_id from the user object.
 * Returns undefined if the field does not exist.
 */
getReadyOn(): Promise<string | undefined> {
  return firstValueFrom(
    this.getUserObservable().pipe(
      // Use the optional chaining operator to safely access the account_id property
      map(user => user?.account_id)
    )
  )
}

/**
 * Returns an Promise of the user's API key.
 * Throws an error if the API key field is not valid or not defined.
 */
getApiKey(): Promise<string> {
  return firstValueFrom(
    this.getUserObservable().pipe(
      map(user => {
        // Check if user object and API key field exist
        if (user && typeof user.api_key === 'string') {
          return user.api_key;
        }
        throw new Error('User object or API key is not defined or invalid.');
      })
    )
  )
}

/**
 * Returns an Promise of the user's organization id.
 * Throws an error if the organization_id field is not valid or not defined.
 */
organizationId(): Promise<string> {
  return firstValueFrom(
    this.getUserObservable().pipe(
      map(user => {
        // Check if user object and organization_id field exist
        if (user && typeof user.organization_id === 'string') {
          return user.organization_id;
        }
        throw new Error('User object or organization_id is not defined or invalid.');
      })
    )
  )
}
  /**
 * Sets the validNocId property of the user object.
 * @param val The boolean value to be set for validNocId
 * @author Brady Synstelien
 */
setNocUser(val: boolean): void {
  this.validNocId = val;
}

/**
 * Checks if the user is a NOC user by returning the value of validNocId.
 * @returns Boolean representing whether the user is a NOC user or not
 * @author Brady Synstelien
 */
isNocUser(): boolean {
  return this.validNocId;
}

}