/**
 * AdministratorGuard class
 *
 * @class AdministratorGuard
 *
 * @description
 * Guard to ensure security for ReadyView admin pages only.
 * It checks if the user is an admin before routing to the destination.
 * If the user is not an admin, they are redirected to the login page.
 *
 * @author Brady Synstelien
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserProfileService } from '../services/user-profile.service';

@Injectable({
  providedIn: 'root'
})
export class AdministratorGuard {
  // Injecting Router and UserProfileService in the constructor
  constructor(private router: Router, private userProfile: UserProfileService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // Check if the user is an admin using UserProfileService
      return this.userProfile
        .isAdmin()
        .then(
          (res) => {
            // If the user is not an admin, navigate them to the login page and replace the current URL
            if(!res) this.router.navigate(['/login'], {replaceUrl: true})
            // If the user is an admin, return true and proceed with navigation
            return res
          }
        )
  } 
}
