export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyC3xkd0saxZw649MFV2pDsMPYFwS1kP-wg",
    authDomain: "readyview-5ff37.firebaseapp.com",
    databaseURL: "https://readyview-5ff37.firebaseio.com",
    projectId: "readyview-5ff37",
    storageBucket: "readyview-5ff37.appspot.com",
    appId: "1:624704369489:android:0523ab6bbe4c3befd69970",
  },
};

export const uri = {
  fast: "https://api.readylinks.io/", // production 
  flask: "https://www.ready-view.com/", // production 
  poller: "http://localhost:8080/" // production
}

export const appVersion = "2.1.0"